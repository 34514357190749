@font-face {
  font-family: "Clinique’s Helvetica Neue wf";
  src: url("../assets/fonts/CliniquesHelveticaNeue-Lt.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family: "Clinique’s Helvetica Neue wf";
  src: url("../assets/fonts/CliniquesHelveticaNeue-LtIt.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family: "Clinique’s Helvetica Neue wf";
  src: url("../assets/fonts/CliniquesHelveticaNeue.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family: "Clinique’s Helvetica Neue wf";
  src: url("../assets/fonts/CliniquesHelveticaNeue-It.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family: "Clinique’s Helvetica Neue wf";
  src: url("../assets/fonts/CliniquesHelveticaNeue-Md.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family: "Clinique’s Helvetica Neue wf";
  src: url("../assets/fonts/CliniquesHelveticaNeue-MdIt.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family: "Clinique’s Helvetica Neue wf";
  src: url("../assets/fonts/CliniquesHelveticaNeue-Bd.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Clinique’s Helvetica Neue wf";
  src: url("../assets/fonts/CliniquesHelveticaNeue-BdIt.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}
